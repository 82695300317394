<script>
import axios from "axios";
import VueSimpleSpinner from 'vue-simple-spinner';

export default {
  data() {
    return {
      loading: false,
      formError: false,
      success: false
    }
  },

  components: {
    VueSimpleSpinner
  },

  methods: {
    submitForm(event) {
      this.resetForm();
      this.loading = true;

      this.sendFormData(event.target);
    },

    resetForm() {
      this.formError = false;
      this.success = false;
    },

    sendFormData(form) {
      const formData = new FormData(form);

      axios({
        method: 'post',
        url: '/',
        data: formData,
        responseType: 'json'
      })
      .then((response) => {
        this.loading = false;
        // console.log(response.data);
        if (!response.data.success) {
          this.success = false
          this.formError = response.data.message
        }

        if (response.data.success) {
          this.formErrors = false
          this.success = true
        }
      })
      .catch((error) => {
        this.loading = false;
        this.formError = 'There was an unknown error submitting the form, please try again.'
        console.log('Mailing List Error:', error);
      })
    }
  }
}
</script>
