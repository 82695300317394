

<script>
export default {
  name: 'MainMenu',

  data() {
    return {
      open: false
    }
  },

  methods: {
    openMenu() {
      this.open = !this.open
    }
  },

  render() {
    return this.$scopedSlots.default({
      open: this.open,
      openMenu: this.openMenu
    })
  }
}
</script>
