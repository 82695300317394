<template>
  <div>
    <slot v-on:accordion-panel-toggled="togglePanels" />
    <div class="text-right">
      <button v-if="panels.length > 3" class="ml-auto text-red-500 font-title uppercase text-lg tracking-widest" @click="toggleAll">
        <span v-if="allExpanded">Collapse All</span>
        <span v-else>Expand All</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panels: [],
      allExpanded: false
    }
  },

  created() {
    this.panels = this.$children;

    this.$on("accordion-panel-toggled", this.togglePanels);
  },

  methods: {
    togglePanels(data) {
      // don't need to do anything on a panel closing
      if (data.open) {
        this.panels.forEach(panel => {
          if (panel.htmlId !== data.htmlId) {
            panel.closePanel()
          }
        });
      }
    },
    
    toggleAll() {
      this.panels.forEach(panel => {
        this.allExpanded === true ?
          panel.closePanel()
          : panel.openPanel();
      });

      this.allExpanded = !this.allExpanded
    }
  }
}
</script>
