// Vue
import Vue from 'vue';

// adds class to body so we can enable/disable focus rings
// https://github.com/ten1seven/what-input
import 'what-input';

// form data polyfill 
import "formdata-polyfill";

// lazy image loading
import 'lazysizes/plugins/respimg/ls.respimg';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import 'lazysizes/plugins/object-fit/ls.object-fit';
// import 'lazysizes/plugins/aspectratio/ls.aspectratio';
import lazySizes from 'lazysizes';

import MainMenu from "./components/MainMenu.vue";
import MailChimpForm from "./components/MailChimpForm.vue";
import MerchantSlider from "./components/MerchantSlider.vue";
import SvgIcon from './components/SvgIcon';
import AccordionGroup from "./components/accordion/AccordionGroup.vue";
import AccordionPanel from "./components/accordion/AccordionPanel.vue";

// Global component
Vue.component('svg-icon', SvgIcon)

new Vue({
  el: '#app',

  delimiters: ['${', '}'],

  data: {},

  components: {
    MainMenu,
    MailChimpForm,
    MerchantSlider,
    AccordionGroup,
    AccordionPanel
  }
})