<template>
  <svg :class="combinedClasses">
    <use fill="currentColor" :xlink:href="href"></use>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: "w-6 h-6"
    }
  },

  computed: {
    combinedClasses() {
      return `${this.prefix}${this.name}-dims ${this.classes}`;
    },

    href() {
      return `#${this.prefix}${this.name}`;
    }
  }
};
</script>
