<template>
  <div class="mb-4">
    <button 
      class="w-full appearance-none text-left flex items-center mb-4" 
      @click="togglePanel" 
      :aria-expanded="open" 
      :aria-controls="htmlId"
    >
      <slot name="header" :open="open"></slot>
      <svg-icon :name="iconName" classes="w-4 h-4 ml-2 text-brown-darkest"></svg-icon>
    </button>

    <div :id="htmlId" :aria-hidden="!open" v-show="open">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
/** 
 * Accordion panel can be used without accordion group if you don't need the auto-collapsing feature
 */

import UuidGenerator from '../../lib/uuid.js'

export default {
  mixins: [UuidGenerator],

  props: {
    expanded: {
      default: false
    }
  },

  data() {
    return {
      open: this.expanded,
    }
  },

  computed: {
    htmlId() {
      return `collapsible-${this.uuid}`;
    },
    iconName() {
      return this.open ? 'minus' : 'plus';
    }
  },

  methods: {
    togglePanel() {
      this.open = !this.open;

      this.$parent.$emit("accordion-panel-toggled", { 
        htmlId: this.htmlId,
        open: this.open 
      });
    },

    closePanel() {
      this.open = false;
    },

    openPanel() {
      this.open = true;
    }
  }
}
</script>
