<script>
// Glide js Slider
import Glide from '@glidejs/glide'

export default {
  mounted() {
    new Glide(this.$el, {
      type: 'carousel',
      perView: 4,
      gap: 0,
      breakpoints: {
        1100: {
          perView: 3,
        },
        800: {
          perView: 2.2,
        },
        600: {
          perView: 1.5,
        }
      }
    }).mount()
  }
}
</script>

<style lang="scss">
  .glide__arrow {
    position: absolute;
    display: block;
    top: 50%;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    padding: 9px 12px;
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;
    background-color: rgba(0,0,0,0.25);
    border-radius: 5px;
  }

  .glide__arrow--left {
    left: 0.5rem;
  }

  .glide__arrow--right {
    right: 0.5rem;
  }
</style>

